<template>
    <el-dialog v-model="dialogFormVisible" fullscreen closed="closeDialog" title="Add misc docs">
        <el-form ref="form" :model="form">
            <el-form-item label="Insert Your Html" :label-width="formLabelWidth">
                <el-input v-model="importedHtml" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" />
            </el-form-item>
        </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button @click="insertContent()">Insert Doc</el-button>
        </span>
      </template>
    </el-dialog>
  </template>

  <script>

  import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
  import MiscDocCards from './MiscDocCards.vue'

  export default {
      props: {
          show: Boolean
      },
      components: {
      EditorContent,
      FloatingMenu,
      MiscDocCards
      },
      data:() => ({
          dialogFormVisible: false,
          editor: null,
          importedHtml: ''
      }),
      unmounted() {
          this.$emit("close")
      },
      methods: {
          closeDialog() {
          this.$emit("close")
          },
          set(project) {
            this.editor.commands.setContent(project.content)
          },
          insertContent() {
            var currentData = this.importedHtml.trim()
            this.$emit("insert", currentData)
            this.dialogFormVisible = false
          }
      },
      mounted() {
          this.dialogFormVisible = this.show
      }
  }

  </script>

  <style lang="scss">

//   .style-external-editor {
//       background-color: black;
//       pre {
//           min-width: 100%;
//       }
//   }


  </style>