<template>
    <div style="height: 100%" v-if="response">
        <a :href="response.pullUrl" target="_blank" class="p-card-subtitle">First Generated Draft</a>
        <MarkdownEditor :content="draftContent" :repo="repo" title="Some Cool Title" :docs-location="locationValue" />
    </div>
    <div v-else>
        <div style="display: flex; flex-direction: column;" class="p-field">
            <label class="mt-4 mb-4 p-card-subtitle">Where do you want to create the new Document or Page</label>
            <InputGroup>
                <InputGroupAddon>
                    <i class="pi pi-folder"></i>
                </InputGroupAddon>
                <AutoComplete v-model="locationValue" dropdown :suggestions="items" @complete="search" />
            </InputGroup>

            <div style="display: flex; flex-direction: column !important; gap: 1em;" class="p-field p-mt-4">
                <label for="folders">What existing docs do you want to use as context</label>
                <MultiSelect v-model="selectedFolders" :options="folderOptions" placeholder="Select folders"
                    display="chip" />
            </div>

            <label class="mt-4 mb-4 p-card-subtitle">Do you want to add additional context</label>

            <SelectButton v-model="additionalOptionsValue" :options="additonalContextOptions" multiple
                aria-labelledby="multiple" optionLabel="name" placeholder="Select OpenAPI files" />
            <div style="display: flex; flex-direction: column !important; gap: 1em;" class="p-field" v-if="additionalOptionsValue?.length">
                <label class="mr-4 p-card-subtitle" for="repos">OpenAPI Files</label>
                <MultiSelect pt:filterIcon:style="vertical-align: unset !important;" id="repos" v-model="openapiRoutes"
                    :options="openapiOptions" optionLabel="path" placeholder="Select OpenAPI Files" display="chip" />
            </div>
        </div>


        <span class="w-full mt-4 mb-4 p-card-subtitle">Your Prompt</span>
        <div style="display: flex; position: relative;">
            <Textarea
                placeholder="Your document prompt, for example 'A new Overview Page on our python sdk', 'a blog post and telemetry', and 'etc'"
                class="w-full mt-4 mb-4" autoResize v-model="value" variant="filled" autofocus fluid rows="2" />
            <Button @click="generateTask" class="mt-6 mb-4 mr-4" icon="pi pi-send" rounded aria-label="Filter"
                style="position: absolute; top: 20; right: 0;" />
        </div>


    </div>



    <Skeleton v-if="loading" width="100%" height="10rem">

    </Skeleton>


</template>


<script>
import ApiKey from '@/views/ApiKey.vue';
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import MarkdownEditor from '@/components/NewMarkdownEditor.vue';

export default {
    props: {
        repo: String,
    },
    components: {
        EditorContent,
        FloatingMenu,
        MarkdownEditor
    },
    data() {
        return {
            value: '',
            additionalOptionsValue: null,
            locationValue: 'docs',
            items: ["docs", "blog"],
            loading: false,
            response: null,
            selectedFolders: [],
            folderOptions: [],
            editorOptions: [
                "Edit raw markdown", "Use rich text editor(preview)"
            ],
            editorSelectvalue: 'Edit raw markdown',
            openapiOptions: [],
            openapiRoutes: [],
            draftContent: null,
            additonalContextOptions: [
                { name: "openapi files", value: "openapi files" }
            ],
            editor: null
        }
    },
    async mounted() {
        let component = this
        let items = await this.fetchOpenApi()
        console.log("what is the items", items)
        this.openapiOptions = items
        await this.getFolders()
    },
    watch: {
        additionalOptionsValue: function (val) {
            console.log("what is the value", val)
        },
        openapiRoutes: function (val) {
            console.log("what is the value", val)
        }
    },
    methods: {
        search(event) {
            this.items = ["docs", "blog"]
        },
        async generateTask() {
            this.loading = true
            const myHeaders = new Headers();
            let page = false
            let sub_folder;
            if (this.locationValue.startsWith("docs/")) {
                sub_folder = this.locationValue.split("docs/")[1] || "docs"
            } else {
                page = true
                sub_folder = this.locationValue
            }
            let task = this.value
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                let githubRepository = this.repo || `team-dev-docs/${org}-dev-docs`
                let requestBody = { task, githubRepository, sub_folder, page, apiKey: "dsfds" }
                if(this.selectedFolders?.length > 0) {
                    requestBody.folders = this.selectedFolders
                }
                if (this.openapiRoutes?.length > 0 && this.additionalOptionsValue?.length) {
                    requestBody.openApiFiles = this.openapiRoutes
                }

                const raw = JSON.stringify(requestBody);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/task`, requestOptions);
                this.loading = false
                this.value = ''
                const result = await saveResponseTwo.json();

                this.response = { content: result.content, branch: result.branch, pullUrl: `https://github.com/${githubRepository}/pulls` }
                this.draftContent = this.response.content
                this.editor.commands.setContent(result.content)
            } catch (e) {

                return {}
            }
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
            } catch (e) {
                this.folderOptions = []
            }
        },
        async updateBlock() {
            let content = this.draftContent
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var raw = JSON.stringify({ message: options.historicalMessage || this.command })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/proxy_url/message`, requestOptions)
                var result = await saveResponseTwo.json()


                this.output = result?.content
                this.command = ""


            } catch (e) {

            }
        },
        async fetchOpenApi() {
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                let url = await this.$authInstance.getBaseUrl()
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                const response = await fetch(`${url}/openapi`, requestOptions);
                const result = await response.json();
                console.log("what is the result", result)
                return result.configs
            } catch (e) {
                return {}
            }
        }
    }
}

</script>