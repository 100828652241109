<template>
    <el-dialog v-model="dialogFormVisible" fullscreen closed="closeDialog" title="Add misc docs">
        <MiscDocCards @set="set" />
        <editor-content autocorrect="off" autocapitalize="off" spellcheck="false" :editor="editor" />
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button @click="insertContent()">Insert Doc</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { lowlight } from 'lowlight'
import { Editor, EditorContent, VueNodeViewRenderer, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import CodeBlockComponent from './CodeBlock.vue'
import Focus from '@tiptap/extension-focus'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Image from '@tiptap/extension-image'
import ResizableImage from './custom_extensions/ImageUpload'
import MermaidBlock from './custom_extensions/MermaidBlock'
import Dropcursor from '@tiptap/extension-dropcursor'
import FormulaComponent from './custom_extensions/FormulaExtension.js'
import FormulaMark from './custom_extensions/FormulaMark.js'

import Commands from './custom_extensions/commands'
import suggestion from './custom_extensions/suggestion'
import MiscDocCards from './MiscDocCards.vue'
import ReferenceMark from './custom_extensions/ReferenceMark'
import Link from '@tiptap/extension-link'

export default {
    props: {
        show: Boolean
    },
    components: {
        EditorContent,
        FloatingMenu,
        MiscDocCards
    },
    data: () => ({
        dialogFormVisible: false,
        editor: null
    }),
    unmounted() {
        this.$emit("close")
    },
    methods: {
        closeDialog() {
            this.$emit("close")
        },
        async handleMapping(docContent) {
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()

            var raw = JSON.stringify(docContent)
            if (!raw.includes("Image")) return docContent
            myHeaders.append("Content-Type", "application/json");

            myHeaders.append("Authorization", `Bearer ${token}`)


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            var url = await this.$authInstance.getBaseUrl()
            var saveResponseTwo = await fetch(`${url}/external_doc/remap_image`, requestOptions)
            var jsonResponse = await saveResponseTwo.json()

            return jsonResponse

        },
        set(project) {
            this.editor.commands.setContent(project.content)
        },
        async insertContent() {
            var currentData = this.editor.getJSON()
            var mapping = await this.handleMapping(currentData)
            if(mapping["external"]) delete mapping["external"]

            this.$emit("insert", mapping)
        }
    },
    mounted() {
        this.dialogFormVisible = this.show
        this.editor = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                ReferenceMark,
                Link,
                StarterKit,
                TaskList,
                Dropcursor,
                FormulaComponent,
                Commands.configure({
                    suggestion,
                }),
                FormulaMark,
                ReferenceMark,
                MermaidBlock.configure({ saved: "yeah yeah" }),
                ResizableImage.configure({ allowBase64: true, inline: true }),
                TaskItem.configure({
                    nested: true,
                }),
                Focus.configure({
                    className: 'has-focus',
                    mode: 'all',
                }),
                CodeBlockLowlight
                    .extend({
                        addNodeView() {
                            return VueNodeViewRenderer(CodeBlockComponent)
                        },
                    })
                    .configure({ lowlight }),
            ],
            content: null,
        })
    }
}

</script>

<style lang="scss">
//   .style-external-editor {
//       background-color: black;
//       pre {
//           min-width: 100%;
//       }
//   }
</style>