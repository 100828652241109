<template>
  <node-view-wrapper @mouseover="hover = true" @mouseleave="hover = false" class="code-block">
    <div style="position: relative;">
      <select v-if="selectedLanguage != 'css'" style="position: absolute; top: 20; right: 0;" contenteditable="false" v-model="selectedLanguage">
      <option :value="null">
        auto
      </option>
      <option disabled>
        —
      </option>
      <option v-for="(language, index) in languages" :value="language" :key="index">
        {{ language }}
      </option>
    </select>
    <pre><code ref="code"><node-view-content /></code></pre>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import EditStylesBar from './EditStylesBar.vue'
import ColorPickerDialog from './GithubSpaces.vue'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,
  mounted() {
    
  },
  data() {
    return {
      languages: this.extension.options.lowlight.listLanguages(),
      hover: false,
      colorPickerDialog: false,
      currentInteraction: false,
      code: ''
    }
  },
  methods: {
    setUrl(val) {
      
      

      const { state } = this.editor
      const { selection } = state
      const { from, to } = selection
      const pos = this.getPos()
      
      const badge = `<a href="${val}" style="display: inline-block; padding: 10px 16px; font-size: 14px; font-weight: bold; text-align: center; text-decoration: none; white-space: nowrap; background-color: #000; color: #fff; border-radius: 6px; border: none; cursor: pointer;">Open in Github Codespaces</a>`
      //this.editor.commands.insertContentAt(pos, `<a href="${val}">open codespace</a>`);
      this.editor.commands.insertContentAt(pos, badge);
      //this.editor.commands.insertContentAt(pos, `<a href="${val}">open codespace</a>`);
    },
    openDialog() {
      const codeElement = this.$refs.code;
      this.code = codeElement.textContent
      this.colorPickerDialog = true
      return
    }
  },
  watch: {
    colorPickerDialog(val) {
      
    }
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.node.attrs.language
      },
      set(language) {
        this.updateAttributes({ language })
      },
    },
  },
}
</script>

<style lang="scss">
.code-block {
  position: relative;

  select {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  space-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

/* Basic editor styles */
.ProseMirror {
  >*+* {
    margin-top: 0.75em;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }

    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #F98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #FBBC88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #B9F18D;
    }

    .hljs-title,
    .hljs-section {
      color: #FAF594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70CFF8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}
</style>
