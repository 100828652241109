<template>
    <figure class="px-10 pt-10 pb-10">
      <h1 class="text-left">Misc docs</h1>
    </figure>



    <div class="docs-holder">

    <div v-if="projects">
      <el-row v-for="(array, index) of projects" :gutter="20" v-bind:key="index">
        <el-col style="margin-top: 2em;" v-for="(project, index) of array" :span="6" v-bind:key="index">
          <el-card @click="getProject(project)" class="box-card">
            <figure v-if="project.file" class="pt-2">
                <h2 class="text-left">{{ getFileName(project.file) }}</h2>
            </figure>
            <div class="pt-10">
              <figure v-if="project.file">
                <p class="text-left">file: {{ getFileName(project.file) }}</p>
              </figure>
              <figure>
                <p class="text-left">from: {{project.filter}}</p>
              </figure>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    </div>
  </template>

  <script>

  // for every foruth element

  var array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  var cutItemsBy4 = function (bigarray) {
    var size = 4; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
      arrayOfArrays.push(bigarray.slice(i, i + size));
    }

    return arrayOfArrays
  }


  export default {
    data: () => ({
      projects: null,
      friendlyNames: null,
      codebases: null,
      showWelcome: false,
      org: null,
      url: null
    }),
    async mounted() {
      //var authStatus = await this.$authInstance.authnActionIfNeeded()
      var idtoken = await this.$authInstance.getToken()
      this.url = await this.$authInstance.getBaseUrl()
      this.org = await this.$authInstance.getOrg()

      await this.getDocs()
      this.showWelcome = this.projects == null
    },
    methods: {
      getFileName(file) {
        var fileFragments = file.split("/")
        return fileFragments[fileFragments.length - 1]
      },
      getProject(project) {

        this.$emit("set", project.content)
      },
      getRepoName(repo) {
        var repoFragments = repo.split("/")
        return repoFragments[repoFragments.length - 1].split(".git")[0]
      },
      generateIdLink(repo) {
        var encodedStringBtoA = btoa(repo);
        return `/docs/${encodedStringBtoA}`
      },
      async getDocs() {
        try {
          var myHeaders = new Headers();
          var idtoken = await this.$authInstance.getToken()
          myHeaders.append("Authorization", `Bearer ${idtoken}`);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          var codebaseRes = await fetch(`${this.url}/docs?searchKey=doctype&searchValue=misc`, requestOptions)
          var codebasesJson = await codebaseRes.json()

          this.projects = cutItemsBy4(codebasesJson.data)

        } catch (e) {

        }
      }
    }
  }
  </script>

  <style>
  /* .docs-holder {
    padding-left: 1em;
  } */
  </style>