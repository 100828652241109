<template>
    <Card>
        <template #title>Create Post Body</template>
        <template #content>
            <div class="p-fluid">
                <div class="p-field">
                    <label>Audits</label>
                    <div v-for="(audit, index) in audits" :key="index" class="p-mb-2 mt-4 mb-4">

                        <Chip style="flex-grow: 0 !important;">
                            <span>{{ audit }}</span>
                            <Button icon="pi pi-times" severity="danger" aria-label="Cancel" text />
                        </Chip>


                    </div>
                    <InputText v-model="newAuditValue" placeholder="Enter audit information" />
                    <Button label="Add Audit" icon="pi pi-plus" @click="addAudit" class="p-mt-2" />
                </div>
                <div class="p-field p-mt-4">
                    <label for="folders">Folders</label>
                    <MultiSelect v-model="selectedFolders" :options="folderOptions" placeholder="Select folders"
                        display="chip" />
                </div>
            </div>

            <Skeleton height="10rem" v-if="loading" fluid />
            <div v-if="auditInfo">
                <ScrollPanel style="width: 100%; height: 500px">
                    <div>
                        <SelectButton v-model="value" :options="options" aria-labelledby="basic" />
                        <DataTable :value="items" v-model:selection="selectedItems" :paginator="true" :rows="10">
                            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                            <Column field="path" header="Path"></Column>
                            <Column field="reason" header="Reason"></Column>
                        </DataTable>

                        <Button v-if="value == 'Generate Issues'" label="Generate Issues" @click="handleGeneration"
                            :disabled="!selectedItems.length" class="mt-3" />
                        <Button v-if="value == 'Fix and Modify'" label="Fix and Modify" @click="handleGeneration"
                            :disabled="!selectedItems.length" class="mt-3" />
                    </div>
                </ScrollPanel>
            </div>

        </template>
        <template #footer>
            <Button label="Audit Your Docs" @click="auditYourDocs" />
        </template>
    </Card>
</template>

<script>
export default {
    data() {
        return {
            audits: [],
            newAuditValue: '',
            selectedFolders: [],
            folderOptions: [],
            loading: false,
            auditInfo: null,
            selectedItems: [],
            value: 'Generate Issues',
            options: ['Generate Issues', 'Fix and Modify'],
        };
    },
    async mounted() {
        await this.getFolders();
    },
    methods: {
        addAudit() {
            let newAuditValue = this.newAuditValue.trim();
            this.audits.push(newAuditValue);
            this.newAuditValue = '';
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
            } catch (e) {
                this.folderOptions = []
            }
        },
        removeAudit(index) {
            console.log(index);
            this.audits.splice(index, 1);
        },
        async handleGeneration() {
            if (this.value === 'Generate Issues') {
                for (let selected of this.selectedItems) {
                    let content = `Need to Modify ${selected.path} because of ${selected.reason}`
                    let title = `Docs Audit: ${selected.reason}`
                    await this.generateIssues({ content, title })
                }
            } else {
                for (let selected of this.selectedItems) {
    
                    await this.updateYourDoc({filePath: selected.path, reason: selected.reason})
                }
            }
        },
        async generateIssues(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let { content, title } = data
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify({ content, title });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/issues`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.auditInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        async updateYourDoc(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify(data);
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/update`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.auditInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        async auditYourDocs() {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify({ folders: this.selectedFolders, audits: this.audits });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/audit`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                this.auditInfo = result
                this.items = result.files
            } catch (e) {
                console.log(e)
                return {}
            }
        }
    }
};
</script>